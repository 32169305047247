import { FormsExtendApi } from './extend-api'
import translations from '../../../utils/translations'
import CoreApi from '../core-api'
import {
  MAIN_APPLICATION_TYPES,
  PRESET_NAME_TO_APPLICATION_NAME,
} from '../../../panels/form-settings-panel/constants'
import { EVENTS } from '../../../constants/bi'
import { FORMS_FEEDBACK } from '../../../panels/form-settings-panel/constants/support-urls'
import _ from 'lodash'
import { FOOTER_CTA_CLICKED } from './event-types'
import { ACTION_TYPE } from './action-types'

export const applicationNameToUrl = (applicationName, baseUrl) => {
  return {
    [MAIN_APPLICATION_TYPES.AUTOMATIONS]: `${baseUrl}/triggers`,
    [MAIN_APPLICATION_TYPES.WORKFLOWS]: `${baseUrl}/workflows`,
    [MAIN_APPLICATION_TYPES.PRICE_QUOTES]: `${baseUrl}/price-quotes`,
    [MAIN_APPLICATION_TYPES.GET_SUBSCRIBERS]: `${baseUrl}/shoutout`,
  }[applicationName]
}

export const getApplicationNameByPreset = (preset) => {
  const footerTypeKey = Object.keys(PRESET_NAME_TO_APPLICATION_NAME).find((key) =>
    _.includes(preset, key),
  )
  return footerTypeKey
    ? PRESET_NAME_TO_APPLICATION_NAME[footerTypeKey]
    : MAIN_APPLICATION_TYPES.AUTOMATIONS
}

const areRulesEnabled = async (coreApi: CoreApi, { isResponsive, isAppWidget }): Promise<boolean> =>
  isResponsive ? coreApi.isExperimentEnabled('specs.crm.FormsEditorXRulesTab') : isAppWidget

const isSubmissionsLimitEnabled = async (
  coreApi: CoreApi,
  { isResponsive, isAppWidget },
): Promise<boolean> => {
  const [
    isSubmissionLimitExperimentEnabled,
    isSubmissionLimitResponsiveExperimentEnabled,
  ] = await Promise.all([
    coreApi.isExperimentEnabled('specs.crm.FormsEditorSubmissionsLimitTab'),
    coreApi.isExperimentEnabled('specs.crm.FormsEditorSubmissionsLimitTabInEditorX'),
  ])
  return (
    isAppWidget &&
    isSubmissionLimitExperimentEnabled &&
    (!isResponsive || isSubmissionLimitResponsiveExperimentEnabled)
  )
}

const isDoubleOptInEnabled = async (coreApi: CoreApi, { isResponsive }): Promise<boolean> =>
  isResponsive ? coreApi.isExperimentEnabled('specs.crm.FormsEditorXDoubleOptIn') : true

const isAutofillFormInfoExperimentEnabled = async (
  coreApi: CoreApi,
  { isResponsive },
): Promise<boolean> =>
  isResponsive
    ? coreApi.isExperimentEnabled('specs.crm.FormsEditorResponsiveAutofillFormInfo')
    : true

const DefaultFormApi = (coreApi: CoreApi): FormsExtendApi => {
  const appConfig = async ({ formComponentRef }): Promise<AppConfig> => {
    const baseUrl = await coreApi.getBusinessManagerUrl()

    const componentConnection = await coreApi.getComponentConnection(formComponentRef)
    const preset = _.get(componentConnection, 'config.preset')

    const applicationName = getApplicationNameByPreset(preset)
    let cta: any = { iconName: applicationName }

    switch (applicationName) {
      case MAIN_APPLICATION_TYPES.PAYMENT:
        cta = {
          ...cta,
          type: ACTION_TYPE.SELECT_TAB,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          tabName: 'payment',
        }
        break
      case MAIN_APPLICATION_TYPES.AUTOMATIONS:
        cta = {
          ...cta,
          type: ACTION_TYPE.SELECT_TAB,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          tabName: 'automations',
        }
        break
      default:
        const href = applicationNameToUrl(applicationName, baseUrl)
        cta = {
          ...cta,
          type: ACTION_TYPE.LINK,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          href,
        }
    }

    const [isResponsive, isAppWidget] = await Promise.all([
      coreApi.isResponsive(),
      coreApi.isAppWidget(componentConnection.controllerRef),
    ])
    const [doubleOptIn, rules, submissionsLimit, autofillFormInfo] = await Promise.all([
      isDoubleOptInEnabled(coreApi, { isResponsive }),
      areRulesEnabled(coreApi, { isResponsive, isAppWidget }),
      isSubmissionsLimitEnabled(coreApi, { isResponsive, isAppWidget }),
      isAutofillFormInfoExperimentEnabled(coreApi, { isResponsive }),
    ])
    return {
      features: {
        notifications: true,
        collection: true,
        contactsSync: true,
        payment: true,
        automations: true,
        doubleOptIn,
        rules,
        submissionsLimit,
        autofillFormInfo,
      },
      mainCTA: {
        description: translations.t('formSettings.mainTab.mainActionContent'),
        actions: [],
      },
      secondaryCTA: {
        cta,
      },
      content: {
        formNameInfo: translations.t('formSettings.settingsTab.info'),
        onSubmitMessage: translations.t('settings.successMessage.default'),
      },
      feedbackUrl: FORMS_FEEDBACK,
    }
  }

  const onFooterCtaClicked = (eventPayload) => {
    const { formId, preset, origin, editorType, action } = eventPayload
    const applicationName = getApplicationNameByPreset(preset)

    if (action.type === ACTION_TYPE.LINK) {
      coreApi.log({
        evid: EVENTS.PANELS.settingsPanel.OPEN_EXTERNAL_APP_LINK,
        form_comp_id: formId,
        template: preset,
        origin,
        application_name: applicationName,
        builderOrigin: editorType,
      })
    }
  }

  const onEvent = ({ eventType, eventPayload }) => {
    switch (eventType) {
      case FOOTER_CTA_CLICKED:
        onFooterCtaClicked(eventPayload)
        break
    }
  }

  return {
    onEvent,
    'settings.getCrucialElements': () => [],
    'fields.getNewFields': () => ({ add: [], remove: [] }),
    appConfig,
  }
}

export default DefaultFormApi
