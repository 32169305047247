import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'

export class Company extends GeneralText {
  public get customFields() {
    return this.base.customFields
  }

  protected get icon() {
    return iconNames.company
  }

  public get fieldType() {
    return FormsFieldPreset.COMPANY
  }

  public get crmType() {
    return CRM_TYPES.COMPANY
  }

  protected get data(): any {
    return {
      textType: 'text',
      placeholder: this.translate(`fieldTypes.${FormsFieldPreset.COMPANY}`),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: this.enableNewCategories() ? CATEGORIES.contact : CATEGORIES.recommended,
        },
      },
      [FormPlugin.REGISTRATION_FORM]: {
        addFieldPanelData: { category: CATEGORIES.none },
      },
    }
  }
}
