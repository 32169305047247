import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { GeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
export class Birthday extends GeneralDatePicker {
  public get customFields() {
    return this.base.customFields
  }

  public get fieldType() {
    return FormsFieldPreset.BIRTHDAY
  }

  //birthday field in server is calling birthdate
  public get crmType() {
    return this.enableFieldMigration() ? CRM_TYPES.BIRTHDATE : CRM_TYPES.DATE
  }

  //TODO: after merge remove getter
  public get crmTag() {
    return this.enableFieldMigration() ? null : CRM_TAGS.BIRTHDAY
  }

  protected get data(): any {
    return { placeholder: this.translate(`fieldTypes.${this.fieldType}`) }
  }
  protected get renderConfig() {

    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: this.enableNewCategories() ? CATEGORIES.contact : CATEGORIES.recommended,
        },
      },
      [FormPlugin.REGISTRATION_FORM]: {
        addFieldPanelData: { category: CATEGORIES.none },
      },
    }
  }
}
