import translations from '../../../utils/translations'
import { CRM_TAGS, crmTypesTags, CUSTOM_FIELD } from '../../../constants/crm-types-tags'
import _ from 'lodash'
import { createSuffixedName } from '../../../utils/utils'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'
import { FIELD_COMPONENT_TYPES, FormsFieldPreset } from '@wix/forms-common'
import { fieldsStore } from '../preset/fields/fields-store'
import { PremiumRestriction } from '../../../constants/premium'
import { AUTOFILL_MEMBER_EMAIL_ROLE } from '../../../constants/roles'
import { getComponentByRole } from '../services/form-service'
import { getPrimaryConnectionFromStructure } from '../utils'

export const getDuplicatedFieldConfig = (
  fields: Partial<FormField>[],
  config: ComponentConfig,
): ComponentConfig => {
  const duplicatedFieldName = translations.t('manageFields.duplicatedFormName', {
    name: config.crmLabel,
  })
  const newCrmLabel = createSuffixedName(
    _.map(fields, (field: Partial<FormField>) => field.crmLabel),
    duplicatedFieldName,
  )
  const isCrmTypeTag = !!crmTypesTags[config.crmType]

  const fieldData = fieldsStore.allFieldsData[config.fieldType]
  const fieldType = fieldData.metadata.allowOnlyOnce
    ? fieldData.base.metadata.fieldType
    : fieldData.metadata.fieldType

  return _.assign({}, config, {
    collectionFieldType: fieldsStore.allFieldsData[config.fieldType].properties.collectionFieldType,
    crmLabel: newCrmLabel,
    crmTag: isCrmTypeTag ? CRM_TAGS.OTHER : config.crmTag,
    crmType: isCrmTypeTag ? config.crmType : CUSTOM_FIELD,
    fieldType,
    customFieldId: undefined,
    customFieldName: undefined,
    collectionFieldKey: createSuffixedName(
      _.map(fields, 'collectionFieldKey'),
      _.camelCase(newCrmLabel),
      '',
    ),
  })
}

export const getDefaultLabel = ({
  titleText,
  buttonLabel,
  label,
  placeholder,
  fieldType,
  labelFromConnection,
}: {
  titleText: string
  buttonLabel: string
  label: string
  placeholder: any
  fieldType: FieldPreset
  labelFromConnection?: string
}) => {
  const componentType = fieldsStore.allFieldsData[fieldType].properties.componentType
  const fieldPlaceholder = _.isString(placeholder) ? placeholder : _.get(placeholder, 'text')

  switch (componentType) {
    case FIELD_COMPONENT_TYPES.FILE_UPLOADER:
      return label || labelFromConnection || buttonLabel
    case FIELD_COMPONENT_TYPES.SIGNATURE_INPUT:
    case FIELD_COMPONENT_TYPES.RATING:
      return titleText || labelFromConnection
    case FIELD_COMPONENT_TYPES.CHECKBOX_GROUP:
      return label || labelFromConnection
    default:
      return label || labelFromConnection || fieldPlaceholder
  }
}

const MAXIMUM_FIELD_NAME_LENGTH = 25

const _getDefaultFieldName = ({
  fieldStructure,
  fieldsOnStage,
}: {
  fieldStructure: any
  fieldsOnStage?: FormField[]
}): string => {
  const data = fieldStructure.data.data
  const { placeholder, titleText, buttonLabel, label, placeholderLabel } = data

  const { crmLabel, fieldType } = fieldStructure.connectionConfig
  const fieldNames = _.map(fieldsOnStage, (field: FormField) => field.crmLabel)

  const defaultPlaceholder: string = placeholder || placeholderLabel

  const defaultLabel: string = getDefaultLabel({
    titleText,
    buttonLabel,
    label,
    placeholder: defaultPlaceholder,
    fieldType,
    labelFromConnection: '',
  })
  const defaultFieldName = (defaultLabel || crmLabel).slice(0, MAXIMUM_FIELD_NAME_LENGTH)

  return createSuffixedName(fieldNames, defaultFieldName, ' ')
}

export const getDefaultFieldName = ({
  fieldStructure,
  fieldsOnStage,
}: {
  fieldStructure: any
  fieldsOnStage?: FormField[]
}): string => {
  const mainRole = getFieldMainRole(fieldStructure.connectionConfig.fieldType)

  if (mainRole) {
    const innerField = getComponentByRole(fieldStructure.data.components[0], mainRole)

    return _getDefaultFieldName({
      fieldStructure: {
        data: {
          data: innerField.data,
        },
        connectionConfig: getConfigFromStructure(innerField),
      },
      fieldsOnStage,
    })
  } else {
    return _getDefaultFieldName({ fieldStructure, fieldsOnStage })
  }
}
export const getFieldByRole = (fields: FormField[], role) => fields.find((f) => f.role === role)

const removeFieldFromListById = (fields: FormField[], id: string) =>
  fields.filter((f) => f.componentRef.id !== id)

export const removeFieldFromList = (fields: FormField[], field: FormField) =>
  removeFieldFromListById(fields, field.componentRef.id)

export const getFieldMainRole = (fieldType: FormsFieldPreset) =>
  fieldType && fieldsStore.allFieldsData[fieldType].metadata.mainRole

export const isFieldAllowedOnceByCrmType = (crmType) => crmType && isCrmTypeAllowedOnce(crmType)

export const isFieldAllowedOnceByFieldType = (fieldType) =>
  fieldType && !!fieldsStore.allFieldsData[fieldType].metadata.allowOnlyOnce

export const isFieldAllowedOnlyOnce = (fieldType, crmType) =>
  isFieldAllowedOnceByFieldType(fieldType) || isFieldAllowedOnceByCrmType(crmType)

export const isCustomFieldAndOnStage = (field: { customFieldId: string }, fieldsOnStage) =>
  field.customFieldId && _.includes(_.map(fieldsOnStage, 'customFieldId'), field.customFieldId)

export const isAllowedOnceAndOnStage = (fieldType: FieldPreset, fieldsOnStage: FormField[]) => {
  const crmType = fieldsStore.allFieldsData[fieldType].crmType
  return (
    (isFieldAllowedOnceByFieldType(fieldType) &&
      _.includes(_.map(fieldsOnStage, 'fieldType'), fieldType)) ||
    (isFieldAllowedOnceByCrmType(crmType) && _.includes(_.map(fieldsOnStage, 'crmType'), crmType))
  )
}

export const getCountableFields = (fields: FormField[]) =>
  _.filter(fields, (field) => {
    const fieldType = _.get(field, 'fieldType')
    const fieldData = fieldsStore.allFieldsData[fieldType]
    return fieldData && fieldData.countable
  })

export const getFieldsLeft = (fields: Array<FormField>, restrictions: PremiumRestriction) => {
  const { limit } = restrictions.fields
  const countableFieldsLength = getCountableFields(fields).length
  return limit > -1 ? limit - countableFieldsLength : Infinity
}

export const getAutofillMemberEmailConnection = (
  connections: Array<Partial<ComponentConnection>>,
): Partial<ComponentConnection> =>
  connections.find(
    (connection: ComponentConnection) => connection.role === AUTOFILL_MEMBER_EMAIL_ROLE,
  )

export const getConfigFromStructure = (structure: ComponentStructure) =>
  JSON.parse(getPrimaryConnectionFromStructure(structure).config)
