import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldRenderConfigType,
  CRM_TYPES,
  CRM_TAGS,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../../constants/roles'
import { iconNames } from '../../../icons/types'
import { CATEGORIES } from '../../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { BaseComplexFieldWidget } from '../../base-complex-field-widget'

export class ComplexAddressWidget extends BaseComplexFieldWidget {
  public get crmType() {
    return CRM_TYPES.ADDRESS
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.ADDRESS]
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get icon() {
    return iconNames.address
  }

  public get customFields() {
    return []
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_ADDRESS_WIDGET
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.COMPLEX_FIELD_WIDGET
  }

  public get role() {
    return FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        connectPanelFieldNameSection: FieldRenderConfigType.REMOVE,
        addFieldPanelData: {
          category: this.enableNewCategories() ? CATEGORIES.contact : CATEGORIES.recommended,
          isNew: true,
        },
      },
    }
  }

  protected get data(): any {
    return {
      name: 'complex-address-controller',
      controllerType: 'complexAddressController',
    }
  }

  protected get layout() {
    return {
      width: 600,
      height: 298,
    }
  }

  public get base() {
    return BaseComplexFieldWidget.prototype
  }

  public get supportedInResponsive() {
    return false
  }
}
