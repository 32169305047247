import { FieldRenderConfigType } from '@wix/forms-common'
import _ from 'lodash'
import { PanelName } from '../../../constants/panel-names'

export const getPanelHeight = (panelName: PanelName, options): number => {
  switch (panelName) {
    case PanelName.CONNECT_FIELD:
      return _getConnectFieldPanelHeight(options)
  }
}

const _getConnectFieldPanelHeight = (options) => {
  const { fieldRenderConfig, asAdvancedSettings, appConfig } = options
  const shouldRemoveFieldName =
    _.get(fieldRenderConfig, 'connectPanelFieldNameSection', '') === FieldRenderConfigType.REMOVE
  const shouldRemoveCrmSync =
    _.get(fieldRenderConfig, 'crmSync', '') === FieldRenderConfigType.REMOVE
  const hasRules = _.get(appConfig, 'features.rules')

  if (shouldRemoveFieldName) {
    return 320
  }
  if (asAdvancedSettings) {
    if (shouldRemoveCrmSync) {
      if (hasRules) {
        return 430
      } else {
        return 270
      }
    } else {
      if (hasRules) {
        return 440
      } else {
        return 280
      }
    }
  } else {
    if (shouldRemoveCrmSync) {
      return 178
    } else {
      return 300
    }
  }
}
