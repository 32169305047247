import { FormsFieldPreset } from '@wix/forms-common'
import {
  GeneralDropdown,
  makeGeneralDropdownOption,
} from '../../../general-fields/definitions/general-dropdown'
import { COMPLEX_ADDRESS_ROLES } from '../../../../../../../constants/roles'
import _ from 'lodash'
import localeData from '../locale-dataset'

export class ComplexAddressCountry extends GeneralDropdown {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_ADDRESS_COUNTRY
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.COMPLEX_ADDRESS_COUNTRY}`)
  }

  public get role() {
    return COMPLEX_ADDRESS_ROLES.COUNTRY
  }

  protected get data(): any {
    return {
      type: 'SelectableList',
      value: '',
      placeholder: {
        value: '',
        text: this.translate(`complexAddress.country.defaultTitle`),
      },
      options: this._options,
    }
  }

  private get _options() {
    return localeData.getAllCountries().map((country) => ({
      ...makeGeneralDropdownOption(),
      value: country.key,
      text: localeData.getCountryByKey(country.key),
    }))
  }
}
