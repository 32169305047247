import { FormsFieldPreset, CustomTypes, FormPlugin, FieldCollectionType } from '@wix/forms-common'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { TextInput } from '../../fields-by-comp-type/definitions/text-input'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { FieldRenderConfig } from '../../field-types-data'

export const URL_REGEX = '^((https?|ftp)://)?[^\\s/$.?#]*\\.[^\\s]*$'

export class GeneralUrl extends TextInput {
  public get customFields() {
    return [CustomTypes.URL]
  }
  
  //TODO: after merge  remove getter
  public get mainCrmTypes() {
    return this.enableFieldMigration() ? [] : [CRM_TYPES.WEBSITE]
  }

  protected get icon() {
    return iconNames.website
  }

  public get collectionFieldType() {
    return FieldCollectionType.URL
  }


  protected get data(): any {
    return {
      textType: 'url',
      pattern: URL_REGEX,
      placeholder: this.translate(`fieldTypes.${this.fieldType}.placeholderText`),
      type: 'TextInput',
      value: '',
    }
  }

  protected get props() {
    return {
      type: 'TextInputProperties',
      required: false,
    }
  }

  protected get renderConfig(): Partial<FieldRenderConfig> {
    const newCategories = this.enableNewCategories()
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: newCategories ? CATEGORIES.url : CATEGORIES.text,
          subCategory: CATEGORIES.basic
        }
      }
    }
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_URL
  }

  public get base() {
    return GeneralUrl.prototype
  }
}
