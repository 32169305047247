import translations from '../../../utils/translations'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { COMPLEX_PHONE_ROLES } from '../../../constants/roles'
import { getComplexPhoneGlobalDesignPresets } from '../preset/preset-service'
import { BASE_DESIGN_GROUPS, COMPONENT_TYPES } from '@wix/forms-common'
import { baseWidgetDesignMappingValues } from '../preset/fields/constants'

const getStyleParamOverrides = () => ({
  [COMPONENT_TYPES.COMBO_BOX_INPUT]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]]: ['txt'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: ['txt_placeholder'],
  },
})

const getTabs = () => {
  const inputsTab = {
    label: translations.t('manifest.globalDesign.inputsCompPart'),
    sections: [
      {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.labelSection'),
        priority: 1,
        subsections: [
          {
            label: translations.t('manifest.globalDesign.labelTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR],
          },
          {
            label: translations.t('manifest.globalDesign.labelRequiredTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR],
          },
        ],
        roles: [],
        styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT],
      },
      {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.inputSection'),
        priority: 2,
        subsections: [
          {
            label: translations.t('manifest.globalDesign.InputTextColor'),
            priority: -1,
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR],
          },
          {
            label: translations.t('manifest.globalDesign.placeholderTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR],
            priority: -2,
          },
        ],
        roles: [],
        styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_FONT],
      },
      {
        state: 'active',
        category: 'fill',
        subsections: [
          {
            label: translations.t('manifest.globalDesign.labelTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.INPUT_BACKGROUND],
          },
          {
            label: translations.t('manifest.complexPhone.globalDesign.iconLabel'),
            styleParam: 'arrowColor',
          },
        ],
        roles: [],
        styleParam: 'arrowColor',
      },
    ],
    styleParamOverrides: getStyleParamOverrides(),
    groups: {
      compTypes: [COMPONENT_TYPES.TEXT_INPUT, COMPONENT_TYPES.COMBO_BOX_INPUT],
    },
  }

  return [inputsTab]
}

const getWidgetDesignManifest = () => ({
  tabs: getTabs(),
  presets: getComplexPhoneGlobalDesignPresets(),
  title: translations.t('manifest.complexPhone.globalDesign.title'),
  presetsTitle: translations.t('manifest.complexPhone.globalDesign.presetsTitle'),
  customHelpId: 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  presetHelpId: '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
})

const getSettingsGfpp = () => ({
  actionId: GFPP_IDS.COMPLEX_PHONE_SETTINGS,
  label: translations.t('fieldSettings.gfppTitle'),
})

export const createComplexPhoneController = () => ({
  default: {
    //TODO: Temporary content. Verify with Jamie this is approved
    displayName: translations.t('manifest.complexPhoneField.widget.displayName'),
    gfpp: {
      desktop: {
        mainAction1: getSettingsGfpp(),
        mainAction2: 'HIDE',
        iconButtons: {
          [GFPP_ACTIONS.CONNECT]: 'HIDE',
          [GFPP_ACTIONS.HOVER_INTERACTIONS]: 'HIDE',
          [GFPP_ACTIONS.LAYOUT]: {
            actionId: GFPP_IDS.COMPLEX_PHONE_LAYOUT,
          },
          [GFPP_ACTIONS.SETTINGS]: { actionId: GFPP_IDS.CONNECT_FIELD },
        },
        widgetDesign: getWidgetDesignManifest(),
        helpId: GFPP.HELP_ID.FIELD,
      },
    },
    connections: {
      [COMPLEX_PHONE_ROLES.DROPDOWN]: {
        displayName: translations.t('manifest.complexPhoneField.dropdown.displayName'),
        gfpp: {
          desktop: {
            mainAction1: getSettingsGfpp(),
            mainAction2: 'HIDE',
            iconButtons: {
              [GFPP_ACTIONS.SETTINGS]: 'HIDE',
              [GFPP_ACTIONS.ANIMATION]: 'HIDE',
              [GFPP_ACTIONS.CONNECT]: 'HIDE',
              [GFPP_ACTIONS.LAYOUT]: 'HIDE',
              [GFPP_ACTIONS.DESIGN]: 'HIDE',
            },
          },
          mobile: {
            iconButtons: {
              [GFPP_ACTIONS.ANIMATION]: 'HIDE',
              [GFPP_ACTIONS.LAYOUT]: 'HIDE',
            },
          },
        },
      },
      [COMPLEX_PHONE_ROLES.TEXT]: {
        displayName: translations.t('manifest.complexPhoneField.text.displayName'),
        behavior: {
          essential: {
            enabled: true,
            text: translations.t('manifest.complexPhoneField.text.restoreText'),
          },
        },
        gfpp: {
          desktop: {
            mainAction1: getSettingsGfpp(),
            mainAction2: 'HIDE',
            iconButtons: {
              [GFPP_ACTIONS.SETTINGS]: 'HIDE',
              [GFPP_ACTIONS.ANIMATION]: 'HIDE',
              [GFPP_ACTIONS.CONNECT]: 'HIDE',
              [GFPP_ACTIONS.LAYOUT]: 'HIDE',
              [GFPP_ACTIONS.DESIGN]: 'HIDE',
            },
          },
          mobile: {
            iconButtons: {
              [GFPP_ACTIONS.ANIMATION]: 'HIDE',
              [GFPP_ACTIONS.LAYOUT]: 'HIDE',
            },
          },
        },
      },
    },
  },
})
