import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  BASE_DESIGN_GROUPS,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { FieldRenderConfig } from '../../field-types-data'

export const generalAutoCompleteAddressDesignMapping = {
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['dropdownBackgroundColor', 'inputBackgroundColor'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: [
    'dropdownDividerStrokeColor',
    'dropdownStrokeColor',
    'inputStrokeColor',
  ],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]: [
    'dropdownDividerStrokeWidth',
    'dropdownStrokeWidth',
    'inputStrokeWidth',
  ],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: ['inputHoverStrokeWidth'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: ['inputFocusStrokeWidth'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: ['inputErrorStrokeWidth'],
  [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['inputBorderRadius'],
  [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: [
    'dropdownHoverTextColor',
    'dropdownLocationIconColor',
    'dropdownTextColor',
    'inputTextColor',
  ],
  [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['inputPlaceholderTextColor'],
  [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: ['dropdownFont', 'inputFont', 'inputPlaceholderFont'],
  [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
  [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: [
    'dropdownHoverBackgroundColor',
    'inputHoverBackgroundColor',
  ],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['inputHoverStrokeColor'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['inputFocusBackgroundColor'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['inputFocusStrokeColor'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['inputErrorBackgroundColor'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['inputErrorStrokeColor'],
  [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['inputBoxShadow', 'dropdownBoxShadow'],
}

export class GeneralAutoCompleteAddress extends IGeneralField {
  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  public get crmType() {
    return CRM_TYPES.ADDRESS
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.ADDRESS]
  }

  public get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.address
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.ADDRESS_INPUT
  }

  public get role() {
    return FIELDS.ROLE_FIELD_AUTOCOMPLETE_ADDRESS
  }

  protected get renderConfig(): Partial<FieldRenderConfig> {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: this.enableNewCategories() ? CATEGORIES.contact : CATEGORIES.recommended,
          isNew: true,
        },
      },
    }
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_AUTOCOMPLETE_ADDRESS
  }

  public get base() {
    return GeneralAutoCompleteAddress.prototype
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.autoCompleteAddress'),
      duplicatable: true,
    })
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return false
  }

  protected get skin() {
    return 'wixui.skins.AddressInput'
  }

  protected get layout() {
    return {
      width: 240,
      height: 42,
    }
  }

  protected get props() {
    return {
      type: 'AddressInputProperties',
      required: false,
    }
  }

  protected get data(): any {
    return {
      type: 'AddressInput',
      placeholder: this.translate(`fieldTypes.${this.fieldType}.placeholderText`),
    }
  }
  public get layoutComponentProps() {
    return ['alignment', 'margin', 'labelPadding', 'labelMargin']
  }

  public get revokedCommonLayoutTransformationProps() {
    return ['alignment']
  }

  public get designMapping() {
    return {
      [this.componentType]: generalAutoCompleteAddressDesignMapping,
    }
  }

  protected get styleProperties() {
    return {
      properties: {
        'alpha-dropdownDividerStrokeColor': '1',
        'alpha-dropdownHoverBackgroundColor': '1',
        'alpha-dropdownLocationIconColor': '1',
        'alpha-inputBackgroundColor': '1',
        'boxShadowToggleOn-dropdownBoxShadow': 'false',
        'boxShadowToggleOn-inputBoxShadow': 'false',
        dropdownBackgroundColor: '#ffffff',
        dropdownBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        dropdownDividerStrokeColor: '#4D4D4D',
        dropdownDividerStrokeWidth: '1',
        dropdownFont: 'normal normal normal 12px/1.4em avenir-lt-w01_85-heavy1475544',
        dropdownHoverBackgroundColor: 'rgba(242,242,242,0.7)',
        dropdownHoverTextColor: '#000000',
        dropdownLocationIconColor: '#4D4D4D',
        dropdownOptionPadding: '10',
        dropdownStrokeColor: '#000000',
        dropdownStrokeWidth: '2',
        dropdownTextColor: '#383838',
        inputBackgroundColor: '#FFFFFF',
        inputBorderRadius: '0px 0px 0px 0px',
        inputBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        inputDisabledBackgroundColor: '#ffffff',
        inputDisabledStrokeColor: '#DBDBDB',
        inputDisabledStrokeWidth: '1',
        inputDisabledTextColor: '#7F808A',
        inputErrorBackgroundColor: '#ffffff',
        inputErrorStrokeColor: '#FF4040',
        inputFocusBackgroundColor: '#ffffff',
        inputFocusStrokeColor: '#000000',
        inputFocusStrokeWidth: '2',
        inputFont: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
        inputHoverBackgroundColor: '#F2F2F2',
        inputHoverStrokeColor: '#5C5C5C',
        inputHoverStrokeWidth: '2',
        inputPlaceholderFont: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
        inputPlaceholderTextColor: '#7F808A',
        inputStrokeColor: '#919191',
        inputStrokeWidth: '2',
        inputTextColor: '#383838',
      },
      propertiesSource: {
        'alpha-dropdownDividerStrokeColor': 'value',
        'alpha-dropdownHoverBackgroundColor': 'value',
        'alpha-dropdownLocationIconColor': 'value',
        'alpha-inputBackgroundColor': 'value',
        'boxShadowToggleOn-dropdownBoxShadow': 'value',
        'boxShadowToggleOn-inputBoxShadow': 'value',
        dropdownBackgroundColor: 'value',
        dropdownBoxShadow: 'value',
        dropdownDividerStrokeColor: 'value',
        dropdownDividerStrokeWidth: 'value',
        dropdownFont: 'value',
        dropdownHoverBackgroundColor: 'value',
        dropdownHoverTextColor: 'value',
        dropdownLocationIconColor: 'value',
        dropdownOptionPadding: 'value',
        dropdownStrokeColor: 'value',
        dropdownStrokeWidth: 'value',
        dropdownTextColor: 'value',
        inputBackgroundColor: 'value',
        inputBorderRadius: 'value',
        inputBoxShadow: 'value',
        inputDisabledBackgroundColor: 'value',
        inputDisabledStrokeColor: 'value',
        inputDisabledStrokeWidth: 'value',
        inputDisabledTextColor: 'value',
        inputErrorBackgroundColor: 'value',
        inputErrorStrokeColor: 'value',
        inputFocusBackgroundColor: 'value',
        inputFocusStrokeColor: 'value',
        inputFocusStrokeWidth: 'value',
        inputFont: 'value',
        inputHoverBackgroundColor: 'value',
        inputHoverStrokeColor: 'value',
        inputHoverStrokeWidth: 'value',
        inputPlaceholderFont: 'value',
        inputPlaceholderTextColor: 'value',
        inputStrokeColor: 'value',
        inputStrokeWidth: 'value',
        inputTextColor: 'value',
      },
      groups: {},
    }
  }
}
