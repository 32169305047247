import { FormsFieldPreset, FieldCollectionType } from '@wix/forms-common'
import { COMPLEX_ADDRESS_ROLES } from '../../../../../../../constants/roles'
import { GeneralText } from '../../../general-fields/definitions/general-text'

export class ComplexAddressCity extends GeneralText {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_ADDRESS_CITY
  }

  public get role() {
    return COMPLEX_ADDRESS_ROLES.CITY
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.COMPLEX_ADDRESS_CITY}`)
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get data() {
    return {
      textType: 'text',
      placeholder: this.translate('complexAddress.city.defaultTitle'),
      type: 'TextInput',
      value: '',
    }
  }
}
