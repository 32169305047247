import { FormsFieldPreset, FieldCollectionType } from '@wix/forms-common'
import { COMPLEX_ADDRESS_ROLES } from '../../../../../../../constants/roles'
import { GeneralText } from '../../../general-fields/definitions/general-text'

export class ComplexAddressZipCode extends GeneralText {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_ADDRESS_ZIPCODE
  }

  public get role() {
    return COMPLEX_ADDRESS_ROLES.ZIP_CODE
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.COMPLEX_ADDRESS_ZIPCODE}`)
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get data() {
    return {
      textType: 'text',
      placeholder: this.translate('complexAddress.zipcode.defaultTitle'),
      type: 'TextInput',
      value: '',
      maxLength: 100,
    }
  }
}
